import styled, { css } from 'styled-components'

import { AccordionStyles, TypeStyle, fromMd } from '@syconium/little-miss-figgy'

import { SecondarySectionTitle } from '../styles'

export const SectionWrapper = styled.div`
  padding: 0 ${o => o.theme.dimensions.layout.sidePadding.md}px;

  ${fromMd} {
    padding: 0;
  }
`

export const AccordionContainer = styled.div`
  border-bottom: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  border-top: 1px solid ${o => o.theme.color.deprecated.stoneLight};

  ${fromMd} {
    padding: 0;
  }

  ${AccordionStyles.BodyContainer} {
    padding-left: 0;
    padding-right: 0;
  }

  ${AccordionStyles.HeaderContainer} {
    padding: 20px 5px 20px 0;

    ${fromMd} {
      padding: 24px 0;
    }
  }
`

const listItemLineHeight = '1.45em'

const verticallySpacedChildren = css`
  * + * {
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
`

export const FabricAndCare = styled.div`
  ${TypeStyle.css.bodySmall}
  ${verticallySpacedChildren}
  font-weight: normal;
`

export const RawMaterials = styled.ul`
  margin-bottom: 20px;
`
export const AccordionTitle = styled(SecondarySectionTitle)`
  text-align: start;
`

export const ProductDetailsAndFitBody = styled.div`
  ${TypeStyle.css.bodySmall}
  ${verticallySpacedChildren}
  font-weight: normal;

  ul {
    &:not(:first-child) {
      margin-top: 18px;
    }
  }
`

export const FeaturesWrapper = styled.div`
  margin-bottom: 20px;
  ${TypeStyle.css.bodySmall};
  font-weight: normal;
  li {
    color: ${o => o.theme.color.deprecated.obsidian};
    padding-inline-start: 36px;
    position: relative;

    &::before {
      background-color: currentColor;
      border-radius: 50%;
      content: '';
      height: 2px;
      margin-inline-start: -25px;
      position: absolute;
      top: calc(${listItemLineHeight} / 2);
      transform: translate(0, -50%);
      width: 2px;
    }
  }
`

export const FitModelsDescriptionsList = styled.ul`
  margin-bottom: 24px;
`

export const ListItem = styled.li`
  ${TypeStyle.css.bodySmall};
  color: ${o => o.theme.color.deprecated.obsidian};
  font-weight: normal;
`

export const CareInstructions = styled(TypeStyle.BodySmall)<{
  withBullets?: boolean
}>`
  color: ${o => o.theme.color.deprecated.obsidian};
  font-weight: normal;
  margin-top: 20px;
`

export const ReviewsCountText = styled.span`
  ${TypeStyle.css.headlineMicro};
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  font-weight: normal;
`

export const NoReviewWrapper = styled.div`
  ${fromMd} {
    margin: 0 0 8px;
  }
`

export const ReviewContainer = styled.div`
  padding: 12px;
  border-radius: 3px;
  border: 1px solid ${o => o.theme.color.deprecated.stoneLight};
  margin-bottom: 16px;

  ${fromMd} {
    border: none;
    background: ${o => o.theme.color.deprecated.stoneLighter};
    margin: 0 0 8px;
  }
`

export const ReviewStarsContainer = styled.div<{
  hasTopMargin?: boolean
}>`
  align-items: center;
  display: flex;
  margin-top: ${o => (o.hasTopMargin ? '8px' : '0px')};
`

export const ButtonWrapper = styled.div`
  > button {
    border-color: ${o => o.theme.color.deprecated.gray.dark};
    color: ${o => o.theme.color.deprecated.gray.dark};

    &:hover {
      background: black;
      border-color: black;
      color: white;
    }
  }
`
export const ReviewAuthor = styled.blockquote`
  ${TypeStyle.css.headlineMicro};
  font-weight: normal;
  color: ${o => o.theme.color.deprecated.obsidianLight};
  margin-left: 20px;

  ${fromMd} {
    margin-left: 10px;
  }
`

export const ReviewText = styled.blockquote`
  ${TypeStyle.css.bodySmall};
  font-weight: normal;
  color: ${o => o.theme.color.deprecated.obsidianLight};
  margin-top: 4px;
`

export const AllReviewsButton = styled.button`
  ${TypeStyle.css.headlineMicro};
  color: ${o => o.theme.color.deprecated.obsidianLighter};
  text-decoration: underline;

  &:hover {
    color: ${o => o.theme.color.deprecated.obsidianLight};
  }
`

export const ReviewStarIconContainer = styled.div`
  display: flex;
  width: 50%;
`

export const ReviewCountTextContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  width: 50%;
  margin-left: 8px;
`

export const ReviewStars = styled.div<{
  rating: number
  isLarge?: boolean
}>`
  display: inline-block;
  font-family: Times;
  font-size: ${o => (o.isLarge ? '16px' : '13px')};
  line-height: 1;

  &::before {
    ${o => {
      const percentage = `${(o.rating / 5) * 100}%`
      return css`
        background: linear-gradient(
          90deg,
          currentColor ${percentage},
          ${o.theme.color.deprecated.stoneLight} ${percentage}
        );
      `
    }}
    background-clip: text;
    -webkit-background-clip: text;
    content: '★★★★★';
    letter-spacing: 3px;
    -webkit-text-fill-color: transparent;
  }
`
