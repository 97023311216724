'use client'

import styled from 'styled-components'

import { TextLink } from '@syconium/little-miss-figgy/dist/components/TextLink'
import { TypeStyle } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { gql } from '@syconium/magnolia/src/__generated__/graphql/catalog'

import { StyledLinkFragmentFragment } from '../../../../../__generated__/graphql/catalog/graphql'
import { ITrackEvent } from '../../../../../lib/analytics/IProps'
import { NextLink } from '../../../navigation/NextLink'

export const styledLinkQueryFragment = gql(`
  fragment StyledLinkFragment on StyledLink {
    __typename  
    id
    text
    url
  }
`)

export type ContentfulStyledLinkProps = StyledLinkFragmentFragment & {
  className?: string
  analyticsContext?: ITrackEvent
}

export const ContentfulStyledLink = styled(
  ({ className, url, text, analyticsContext }: ContentfulStyledLinkProps) => {
    return (
      <TextLink
        as={NextLink}
        className={className}
        href={url}
        variant='underline'
        {...analyticsContext}
      >
        {text}
      </TextLink>
    )
  }
)`
  ${TypeStyle.css.headlineExtraExtraSmall}
`
