import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_PRODUCT_GROUP_FOR_FIT_QUIZ = gql(`
query fetchProductGroupForFitQuiz($handle: String!) {
    productGroup(
        handle: $handle
    ){
        category
        genderCategory
        rawFits
        fits
        fitQuiz {
            ...FitQuizFragment
        }
        sizeChart: sizeChartCG {
            ...SizeChartFragment
        }
    }
}`)
