import { useEffect, useMemo, useRef, useState } from 'react'

import { CustomComponentSectionName } from '../../__generated__/graphql/catalog/graphql'
import { CollectionViewHeroSection } from '../../brunswick/components/CollectionViewHeroSection'
import { AccordionView } from '../../brunswick/components/page-sections/AccordionView/AccordionView'
import { PageRichTextSection } from '../../brunswick/components/page-sections/PageRichTextSection'
import { PageTabSection } from '../../brunswick/components/page-sections/PageTabSection'
import { PageTestimonialSection } from '../../brunswick/components/page-sections/PageTestimonialSection'
import { RawHtmlSection } from '../../brunswick/components/page-sections/RawHtmlSection'
import { VideoSection } from '../../brunswick/components/page-sections/VideoSection'
import { PageSection as PageSectionModel, ProductGroupViewSection } from '../../types/graphql'
import { FitQuizModal } from '../pages/fit-quiz/FitQuizModal'
import { FitQuizModalStateContainer } from '../pages/fit-quiz/hooks/useFitQuizModalState'

import { CollectionSection } from './CollectionSection'
import { StudentDiscount } from './CustomSections/StudentDiscount'
import { PageBannerSection } from './PageBannerSection'
import { PageEditorialCarouselSection } from './PageEditorialCarouselSection'
import { PageHeroSection } from './PageHeroSection'
import { PageInteractiveSlideSection } from './PageInteractiveSlideSection'
import { PageLinksSection } from './PageLinksSection'
import { PageSectionContext, PageSectionContextValue } from './PageSectionContext'
import { PageTileSection } from './PageTileSection'
import { PageTitleSection } from './PageTitleSection'
import { ProductRecommendationsSection } from './ProductRecommendationsSection'

const CustomComponentSection = (componentName: CustomComponentSectionName): JSX.Element | null => {
  if (componentName === 'STUDENT_DISCOUNT') {
    return <StudentDiscount />
  }
  return null
}

export type PageSectionProps = {
  /** The Contentful Gateway Model used to generate a Contentful Component Section. */
  sectionModel: PageSectionModel | ProductGroupViewSection
  /** The index defining what section of the page this is. Leave empty if you are unsure. First sections of the page get preloaded images. */
  index: number
}

function renderPageSection(
  props: PageSectionModel | ProductGroupViewSection,
  index: number
): JSX.Element | null {
  switch (props.__typename) {
    case 'AccordionView':
      return <AccordionView {...props} />
    case 'CollectionSection':
      return <CollectionSection {...props} />
    case 'CollectionViewHeroSection':
      return <CollectionViewHeroSection {...props} isCriticalImage={index === 0} />
    case 'PageBannerSection':
      return <PageBannerSection {...props} />
    case 'PageEditorialCarouselSection':
      return <PageEditorialCarouselSection {...props} />
    case 'PageHeroSection':
      return <PageHeroSection {...props} isCriticalImage={index === 0} />
    case 'PageInteractiveSlideSection':
      return <PageInteractiveSlideSection {...props} />
    case 'PageLinksSection':
      return <PageLinksSection {...props} />
    case 'PageTabSection':
      return <PageTabSection {...props} index={index} />
    case 'PageTestimonialSection':
      return <PageTestimonialSection {...props} />
    case 'PageTileSection':
      return <PageTileSection {...props} />
    case 'PageTitleSection':
      return <PageTitleSection {...props} />
    case 'ProductRecommendationsSection':
      return <ProductRecommendationsSection {...props} />
    case 'RawHtmlSection':
      return <RawHtmlSection {...props} />
    case 'RichTextSection':
      return <PageRichTextSection {...props} />
    case 'VideoSection':
      return <VideoSection {...props} isCriticalVideo={index === 0} />
    case 'CustomComponentSection':
      return CustomComponentSection(props.componentName)
    default:
      return null
  }
}

export const PageSection = ({ sectionModel, index }: PageSectionProps) => {
  const contextValue: PageSectionContextValue = useMemo(
    () => ({
      pageSectionIndex: index,
    }),
    [index]
  )

  const interceptorRef = useRef<HTMLDivElement>(null)

  return (
    <PageSectionContext.Provider value={contextValue}>
      <div ref={interceptorRef}>{renderPageSection(sectionModel, index)}</div>
      <FitQuizModalStateContainer.Provider>
        <PageSectionInterceptedRouteDialog interceptorRef={interceptorRef} />
      </FitQuizModalStateContainer.Provider>
    </PageSectionContext.Provider>
  )
}

const PageSectionInterceptedRouteDialog = ({
  interceptorRef,
}: {
  interceptorRef: React.RefObject<HTMLDivElement>
}) => {
  const { setIsFitQuizModalOpen } = FitQuizModalStateContainer.useContainer()
  const [selectedFitQuizHandle, setSelectedFitQuizHandle] = useState<string | undefined>(undefined)

  //This intercepts the route for a fit quiz to open a modal.
  //When our routing is done via the app directory, NextJS has a way to do this.
  //https://nextjs.org/docs/app/building-your-application/routing/intercepting-routes
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        interceptorRef.current &&
        interceptorRef.current.contains(event.target as Node) &&
        event.target instanceof HTMLAnchorElement
      ) {
        const match = event.target.pathname.match(/^\/fit-quiz\/(.+)/)
        if (match) {
          event.preventDefault()
          setSelectedFitQuizHandle(match[1])
          setIsFitQuizModalOpen(true)
        }
      }
    }
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [interceptorRef, setIsFitQuizModalOpen])

  return selectedFitQuizHandle && <FitQuizModal fitQuizHandle={selectedFitQuizHandle} />
}
