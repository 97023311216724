import { FC } from 'react'

import { PageLinksSectionFragmentFragment } from '../../../__generated__/graphql/catalog/graphql'
import { usePurePathname } from '../../../lib/hooks/usePurePathname'

import {
  PageLinksSectionWrapper,
  UppercaseContentfulStyledLink,
  XXSHeadlineUppercase,
} from './styles'

export const PageLinksSection: FC<PageLinksSectionFragmentFragment> = ({ name, links }) => {
  const currentPathStripped = usePurePathname()
  return (
    <PageLinksSectionWrapper>
      {links.map((link, index) => {
        if (currentPathStripped === link.url)
          return <XXSHeadlineUppercase>{link.text}</XXSHeadlineUppercase>
        return (
          <UppercaseContentfulStyledLink
            key={index}
            {...link}
            analyticsContext={{
              'data-heap-contentful-name': name ?? undefined,
              'data-heap-action': 'click link',
              'data-heap-label': link.url,
              'data-heap-category': link.url,
            }}
          />
        )
      })}
    </PageLinksSectionWrapper>
  )
}
