import { gql } from '../../../__generated__/graphql/catalog'

export const FETCH_FIT_QUIZ = gql(`
query fetchFitQuiz($handle: String!) {
    fitQuiz(
        handle: $handle
    ){
        ...FitQuizFragment
    }
}`)
