import styled from 'styled-components'

import { TypeStyleHeadlineExtraExtraSmall } from '@syconium/little-miss-figgy/dist/components/TypeStyle'
import { fromMd } from '@syconium/little-miss-figgy/dist/themes/default'

import { ContentfulStyledLink } from '../../../app/_components/pages/contentful/components/ContentfulStyledLink.client'

export const PageLinksSectionWrapper = styled.section`
  display: flex;
  padding: ${o => o.theme.spacing(5, 0)};
  gap: ${o => o.theme.spacing(6)};
  align-items: center;
  justify-content: center;
  ${fromMd} {
    min-height: ${o => o.theme.spacing(9)};
  }
`
export const UppercaseContentfulStyledLink = styled(ContentfulStyledLink)`
  ${o => o.theme.typography.effect.uppercase}
  border-style: none;
  padding-bottom: 2px;
  &:hover {
    padding-bottom: 2px;
  }
`
export const XXSHeadlineUppercase = styled(TypeStyleHeadlineExtraExtraSmall)`
  ${o => o.theme.typography.effect.uppercase}
  border-color: currentColor;
  border-style: solid;
  border-bottom-width: 2px;
`
