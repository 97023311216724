import React, { useMemo } from 'react'

import { VimeoVideo } from '@syconium/magnolia/src/brunswick/components/VimeoVideo'
import { defaultAspectRatio } from '@syconium/magnolia/src/brunswick/constants'
import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'
import { InlineVideoPlatforms } from '@syconium/magnolia/src/types/video'

import { RenderOnceInViewport } from '../../../../app/_components/performance/RenderOnceInViewport.client'
import { useFixturesContext } from '../../../../brunswick/containers/fixtures'
import { MaybeSpaLink } from '../../../../lib/adapters/next-routing-service/MaybeSpaLink'
import { useImageLoader } from '../../../../lib/hooks/useImageLoader'
import { useResponsiveVideoSrc } from '../../../../lib/hooks/useResponsiveVideoSrc'
import {
  PageTileSectionTextAlign,
  PageTileSectionTextPlacement,
  PageTileSectionTile as TileProps,
} from '../../../../types/graphql/contentful-components/page-tile-section'

import {
  Container,
  CtaLink,
  Description,
  HoverImage,
  HoverVideo,
  ImageWrap,
  InlineVideo,
  JumboTitle,
  Name,
  Image as StyledImage,
  TextBelow,
  TextOverlay,
  TextOverlayBottom,
  TextOverlayMiddle,
  TextOverlayTop,
  TitleImage,
} from './styles'

export interface Props extends TileProps {
  aspectRatio?: number
  roundCorners?: boolean
  textAlign: PageTileSectionTextAlign
  textPlacement: PageTileSectionTextPlacement
  title?: string | null
  visibleTilesDesktop: number
  visibleTilesMobile: number
  hasVerticalMargin?: boolean
}

export const PageTileSectionTile: React.FC<Props> = ({
  aspectRatio,
  description,
  hoverImage,
  hoverVideo,
  image,
  ctaText,
  ctaLinkHref,
  inlineVideo,
  inlineVimeoId,
  jumboTitle,
  linkHref,
  name,
  sectionName,
  roundCorners,
  textAlign,
  textPlacement,
  title,
  titleImage,
  visibleTilesDesktop,
  visibleTilesMobile,
  hasVerticalMargin = true,
}) => {
  const {
    magnolia: { general },
  } = useFixturesContext()
  const { contentfulImageLoader } = useImageLoader()

  const alt = (jumboTitle || name) ?? ''
  const hasHover = !!(hoverImage || hoverVideo)

  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  const video = useResponsiveVideoSrc({
    inlineVideoDesktop: inlineVideo,
    inlineVideoMobile: inlineVideo,
    inlineVimeoIdDesktop: inlineVimeoId,
    inlineVimeoIdMobile: inlineVimeoId,
  })

  const onClick: (event: React.MouseEvent<HTMLElement>) => void = event => {
    event.stopPropagation()
  }

  const aspectRatios = useMemo(() => {
    return {
      sm: aspectRatio ?? defaultAspectRatio,
      md: aspectRatio ?? defaultAspectRatio,
    }
  }, [aspectRatio])

  return (
    <Container {...{ hasHover, textAlign, hasVerticalMargin }}>
      <MaybeSpaLink
        href={linkHref}
        {...{ onClick }}
        {...trackEvent({
          customAttributes: {
            'data-heap-parent-title': title ?? undefined,
            'data-heap-name': name ?? undefined,
            'data-heap-description': description ?? undefined,
          },
          category: 'page-tile-section-tile',
          action: 'click image',
          pageSectionName: sectionName,
          pageSectionIndex,
          contentfulName: sectionName,
        })}
      >
        <ImageWrap aspectRatio={aspectRatio ?? defaultAspectRatio} roundCorners={roundCorners}>
          {hoverImage && (
            <HoverImage
              alt=''
              loading='lazy'
              src={hoverImage}
              widths={{ unit: 'vw', sm: 100 / visibleTilesMobile, md: 100 / visibleTilesDesktop }}
              aspectRatios={aspectRatios}
            />
          )}
          {hoverVideo && <HoverVideo src={hoverVideo} autoPlay muted playsInline loop />}
          {image && !video && (
            <StyledImage
              alt=''
              loading='lazy'
              src={image}
              widths={{ unit: 'vw', sm: 100 / visibleTilesMobile, md: 100 / visibleTilesDesktop }}
              aspectRatios={aspectRatios}
            />
          )}

          {video?.platform === InlineVideoPlatforms.DEFAULT_PLAYER && (
            <RenderOnceInViewport>
              <InlineVideo src={video.src} autoPlay muted playsInline loop />
            </RenderOnceInViewport>
          )}

          {video?.platform === InlineVideoPlatforms.VIMEO && (
            <VimeoVideo
              autoplay
              lazy
              playText={general.playVideo}
              pauseText={general.pauseVideo}
              thumbnail={
                image && (
                  <StyledImage
                    alt={alt}
                    loading='lazy'
                    src={image}
                    widths={{
                      unit: 'vw',
                      sm: 100 / visibleTilesMobile,
                      md: 100 / visibleTilesDesktop,
                    }}
                    aspectRatios={aspectRatios}
                  />
                )
              }
              videoSrc={video.src}
            />
          )}

          <TextOverlay {...{ textPlacement }} aria-hidden={textPlacement === 'below'}>
            <TextOverlayTop />
            <TextOverlayMiddle>
              {titleImage && (
                <TitleImage
                  src={contentfulImageLoader({ src: titleImage, quality: 90 })}
                  scaleDesktop={0.5}
                  scaleMobile={0.333}
                  loading='lazy'
                />
              )}
            </TextOverlayMiddle>
            <TextOverlayBottom {...{ textPlacement }}>
              {jumboTitle && (
                <>
                  <JumboTitle isUppercase={isUppercase(jumboTitle)}>{jumboTitle}</JumboTitle>
                  <br />
                </>
              )}
              {name && (
                <>
                  <Name isUppercase={isUppercase(name)}>{name}</Name>
                  <br />
                </>
              )}
              {description && <Description>{description}</Description>}
            </TextOverlayBottom>
          </TextOverlay>
        </ImageWrap>
      </MaybeSpaLink>
      <TextBelow {...{ textPlacement }}>
        <>
          {jumboTitle && (
            <>
              <MaybeSpaLink
                href={linkHref}
                tabIndex={-1}
                {...{ onClick }}
                {...trackEvent({
                  category: 'page-tile-section-tile',
                  action: 'click text',
                  label: jumboTitle,
                  pageSectionName: sectionName,
                  pageSectionIndex,
                  contentfulName: sectionName,
                })}
              >
                <JumboTitle asTag='p' isUppercase={isUppercase(jumboTitle)}>
                  {jumboTitle}
                </JumboTitle>
              </MaybeSpaLink>
              <br />
            </>
          )}
          {name && (
            <>
              <MaybeSpaLink
                href={linkHref}
                tabIndex={-1}
                {...{ onClick }}
                {...trackEvent({
                  category: 'page-tile-section-tile',
                  action: 'click text',
                  label: name,
                  pageSectionName: sectionName,
                  pageSectionIndex,
                  contentfulName: sectionName,
                })}
              >
                <Name asTag='h5' isUppercase={isUppercase(name)}>
                  {name}
                </Name>
              </MaybeSpaLink>

              <br />
            </>
          )}
          {description && (
            <MaybeSpaLink
              href={linkHref}
              tabIndex={-1}
              {...{ onClick }}
              {...trackEvent({
                category: 'page-tile-section-tile',
                action: 'click text',
                pageSectionName: sectionName,
                pageSectionIndex,
                contentfulName: sectionName,
              })}
            >
              <Description asTag='p' isUppercase={isUppercase(description)}>
                {description}
              </Description>
            </MaybeSpaLink>
          )}
        </>
      </TextBelow>
      {ctaText && ctaLinkHref && <CtaLink href={ctaLinkHref}>{ctaText}</CtaLink>}
    </Container>
  )
}
