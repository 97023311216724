import { ApolloClient, NormalizedCacheObject, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { FETCH_FIT_QUIZ } from '../../../../lib/graphql/queries/FETCH_FIT_QUIZ'
import { FETCH_PRODUCT_GROUP_FOR_FIT_QUIZ } from '../../../../lib/graphql/queries/FETCH_PRODUCT_GROUP_FOR_FIT_QUIZ'
import { QueryStatus } from '../../../../lib/hooks/types'

type UseFetchProductGroupForFitQuizProps = {
  productGroupHandle: string
  includeUnavailable?: boolean
  isPreview?: boolean
  launchTags?: string
}

type UseFetchFitQuizProps = {
  fitQuizHandle: string
  includeUnavailable?: boolean
  isPreview?: boolean
  launchTags?: string
}

export const primeClientForFetchProductGroupForFitQuizData = ({
  client,
  props,
}: {
  client: ApolloClient<NormalizedCacheObject>
  props: UseFetchProductGroupForFitQuizProps
}) => {
  const { productGroupHandle, includeUnavailable, isPreview, launchTags } = props

  return [
    client.query({
      errorPolicy: 'all',
      query: FETCH_PRODUCT_GROUP_FOR_FIT_QUIZ,
      variables: {
        handle: productGroupHandle,
      },
      context: { includeUnavailable, isPreview, launchTags },
    }),
  ] as const
}
export const useFetchProductGroupForFitQuiz = ({
  productGroupHandle,
  includeUnavailable,
  isPreview,
  launchTags,
}: UseFetchProductGroupForFitQuizProps) => {
  const queryContext = useMemo(() => {
    return { includeUnavailable, isPreview, launchTags }
  }, [includeUnavailable, isPreview, launchTags])
  const {
    data: productGroupData,
    error: productGroupError,
    loading: productGroupLoading,
    previousData: previousProductGroupData,
    called: productGroupCalled,
  } = useQuery(FETCH_PRODUCT_GROUP_FOR_FIT_QUIZ, {
    variables: {
      handle: productGroupHandle,
    },
    context: queryContext,
  })
  const productGroup = productGroupLoading
    ? previousProductGroupData?.productGroup
    : productGroupData?.productGroup
  const productGroupStatus = useMemo<QueryStatus>(() => {
    if (productGroupLoading) return 'pending'
    if (productGroupError) 'rejected'
    if (productGroupCalled || productGroup) return 'resolved'
    return 'idle'
  }, [productGroup, productGroupCalled, productGroupError, productGroupLoading])
  const productGroupNotFound: boolean = productGroup === null && productGroupStatus === 'resolved'

  return {
    productGroup,
    productGroupStatus,
    productGroupNotFound,
    productGroupError,
  }
}

export const useFetchFitQuiz = ({
  fitQuizHandle,
  includeUnavailable,
  isPreview,
  launchTags,
}: UseFetchFitQuizProps) => {
  const queryContext = useMemo(() => {
    return { includeUnavailable, isPreview, launchTags }
  }, [includeUnavailable, isPreview, launchTags])
  const {
    data: fitQuizData,
    error: fitQuizError,
    loading: fitQuizLoading,
    previousData: previousFitQuizData,
    called: fitQuizCalled,
  } = useQuery(FETCH_FIT_QUIZ, {
    variables: {
      handle: fitQuizHandle,
    },
    context: queryContext,
  })
  const fitQuiz = fitQuizLoading ? previousFitQuizData?.fitQuiz : fitQuizData?.fitQuiz
  const fitQuizStatus = useMemo<QueryStatus>(() => {
    if (fitQuizLoading) return 'pending'
    if (fitQuizError) 'rejected'
    if (fitQuizCalled || fitQuiz) return 'resolved'
    return 'idle'
  }, [fitQuiz, fitQuizCalled, fitQuizError, fitQuizLoading])
  const fitQuizNotFound: boolean = fitQuiz === null && fitQuizStatus === 'resolved'

  return {
    fitQuiz,
    fitQuizStatus,
    fitQuizNotFound,
    fitQuizError,
  }
}

export const primeClientForFetchFitQuizData = ({
  client,
  props,
}: {
  client: ApolloClient<NormalizedCacheObject>
  props: UseFetchFitQuizProps
}) => {
  const { fitQuizHandle, includeUnavailable, isPreview, launchTags } = props

  return [
    client.query({
      errorPolicy: 'all',
      query: FETCH_FIT_QUIZ,
      variables: {
        handle: fitQuizHandle,
      },
      context: { includeUnavailable, isPreview, launchTags },
    }),
  ] as const
}
