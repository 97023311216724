import styled from 'styled-components'

import { TextLink, TypeStyle } from '@syconium/little-miss-figgy'

import {
  StyledLink,
  StyledLinkFragmentFragment,
} from '../../../__generated__/graphql/catalog/graphql'
import { MaybeSpaLinkWrapper } from '../../../lib/adapters/next-routing-service/MaybeSpaLinkWrapper'
import { ITrackEvent } from '../../../lib/analytics'

export type ContentfulStyledLinkProps = (StyledLinkFragmentFragment | StyledLink) & {
  className?: string
} & {
  analyticsContext: ITrackEvent
}

export const ContentfulStyledLink = styled(
  ({ className, url, text, analyticsContext }: ContentfulStyledLinkProps) => {
    return (
      <MaybeSpaLinkWrapper href={url}>
        <TextLink as='a' className={className} href={url} variant='underline' {...analyticsContext}>
          {text}
        </TextLink>
      </MaybeSpaLinkWrapper>
    )
  }
)`
  ${TypeStyle.css.headlineExtraExtraSmall}
`
