import { ComponentProps, FC } from 'react'

import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { SizeChartModalContent } from '../../../brunswick/components/size-chart/SizeChartModal/SizeChartModalContent'
import { HeaderTextSmall } from '../../../brunswick/components/size-chart/SizeChartModal/SizeChartModalTable/styles'
import {
  CloseButton,
  Header,
  modalStyles,
} from '../../../brunswick/components/size-chart/SizeChartModal/styles'
import { useFixturesContext } from '../../../brunswick/containers/fixtures'
import {
  modalAnimationDuration,
  standardModalBodyOpenClassName,
  standardModalPortalClassName,
} from '../../../brunswick/styles/constants'
import { NextModal } from '../../modals/NextModal'

import { FitQuiz } from './FitQuiz'
import { useFetchFitQuiz } from './hooks/useFetchFitQuiz'
import { FitQuizModalStateContainer } from './hooks/useFitQuizModalState'
import { ModalBody } from './styles'

export const FitQuizModal: FC<{ fitQuizHandle: string }> = ({ fitQuizHandle }) => {
  const { isFitQuizModalOpen, setIsFitQuizModalOpen } = FitQuizModalStateContainer.useContainer()
  const { fitQuiz } = useFetchFitQuiz({
    fitQuizHandle,
  })
  const {
    magnolia: {
      fitQuiz: { findMyFit },
    },
  } = useFixturesContext()
  const fitQuizData: ComponentProps<typeof SizeChartModalContent>['fitQuiz'] = fitQuiz
    ? {
        rawFits: [],
        preferredGender: undefined,
        closeModal: () => {
          setIsFitQuizModalOpen(false)
        },
        ...fitQuiz,
      }
    : undefined

  return (
    <NextModal
      bodyOpenClassName={standardModalBodyOpenClassName}
      closeTimeoutMS={modalAnimationDuration}
      isOpen={isFitQuizModalOpen}
      onRequestClose={undefined}
      portalClassName={standardModalPortalClassName}
      style={modalStyles}
    >
      <ModalBody>
        {fitQuizData && (
          <>
            <Header backgroundColor='white' hasBottomBorder={true}>
              <HeaderTextSmall asTag='h2'>{findMyFit}</HeaderTextSmall>
              <CloseButton
                onClick={() => {
                  setIsFitQuizModalOpen(false)
                }}
                {...trackEvent({ category: 'fit quiz', action: 'close modal' })}
              />
            </Header>
            <FitQuiz {...fitQuizData} />
          </>
        )}
      </ModalBody>
    </NextModal>
  )
}
